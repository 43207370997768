@keyframes ui-progress-spinner-color {
    100%,
    0% {
        stroke: var(--primary-color);
    }
    40% {
        stroke: var(--primary-color);
    }
    66% {
        stroke: var(--primary-color);
    }
    80%,
    90% {
        stroke: var(--primary-color);
    }
}
