:root {
    --primary-color: #06a29b;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
a:hover,
a:active,
a:focus,
a:visited {
    color: #06a29b;
    text-decoration: none;
}

a.p-button {
    color: #fff;
}

.p-button,
.p-button:enabled:hover {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.p-button.p-button-outlined,
.p-button.p-button-outlined:hover {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: var(--primary-color);
}

.p-toast.p-component {
    z-index: 1003 !important;
}

.headline {
    display: flex;
    flex-flow: column-reverse wrap;
}

.headline__title {
    margin-top: 0;
}

.headline__subtitle {
    color: gray;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}
