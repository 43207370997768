label {
    align-items: center;
    color: var(--text-color);
    display: flex;
    font-size: 16px;
}

.store-offers-preview + .store-offers-preview {
    margin-top: 80px;
}

.plain-link-button {
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer !important;
    outline: none;
    padding: 0;
}
