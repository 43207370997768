.header {
    align-items: center;
    background: #fff;
    box-shadow: 0 0 5px rgb(33 150 243 / 27%);
    display: flex;
    height: 80px;
    justify-content: space-between;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1001;
}

.logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.logo > img {
    display: block;
    max-height: 60px;
}
