.form__error-message {
    color: #d32f2f;
    display: block;
    font-size: 0.85rem;
    margin: 0.25rem 0;
}

.infotable {
    border-collapse: separate;
    border-spacing: 0 15px;
    margin-bottom: 40px;
    table-layout: fixed;
    width: 100%;
}

.infotable-heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.infotable tr + tr {
    border-top: 1px dotted #ccc;
}

.infotable th,
.infotable td {
    line-height: 1.5em;
    width: 50%;
}

.infotable th {
    text-align: left;
    vertical-align: top;
}

.infotable td {
    text-align: right;
    vertical-align: top;
}

@media screen and (max-width: 640px) {
    .infotable tr {
        align-items: flex-start;
        display: flex;
        flex-flow: column wrap;
        padding: 20px 0;
    }

    .infotable th,
    .infotable td {
        flex: 1 1 100%;
        width: auto;
    }
}

label {
    color: var(--text-color);
}

.p-button.p-fileupload-choose.p-component {
    background: var(--primary-color) !important;
}

.form-wrapper {
    margin: 0 auto;
    max-width: 768px;
}

.actions {
    justify-content: space-between;
}

.actions > * {
    display: flex;
    flex: 0 1 40%;
    justify-content: center;
    max-width: 300px;
    text-align: center;
}
