.backdrop {
    background: rgba(0, 0, 0, 0.5);
    /* backdrop-filter: blur(5px); */
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
}

.modal {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    border-radius: 10px;
}

.p-listbox {
    background: transparent !important;
    border: none !important;
}
