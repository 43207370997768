.footer-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-links li {
    margin: 0 5px;
}
