.signup-login-page {
    height: calc(100vh - 100px);
}

.p-tabview-nav {
    flex-wrap: nowrap !important;
    overflow-x: auto;
}

.signup-login-page a[role="tab"] {
    white-space: nowrap;
}

.p-tabview .p-tabview-nav {
    overflow-y: hidden;
}
