.p-fileupload {
    text-align: center;
}

.p-fileupload-content::after {
    content: "Bild hier ablegen";
    color: #ccc;
}

.p-fileupload-files {
    display: none;
}

.p-fileupload-choose:not(.p-disabled):hover {
    background: var(--primary-color) !important;
}

.image-upload-area {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
}

.image__item {
    display: inline-block;
    flex: 0 1 33%;
    padding: 5px;
    position: relative;
}

@media screen and (max-width: 640px) {
    .image__item {
        flex: 0 1 50%;
    }
}

@media screen and (min-width: 1024px) {
    .image__item {
        flex: 0 1 20%;
    }
}

.p-button.image__remove-x {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 10;
}

.image {
    height: auto;
    width: 100%;
}

.p-fieldset.p-fieldset-legend {
    border: none;
    background: none;
}

.p-progressbar-label {
    color: #fff !important;
}
