.feedback-icon {
    background: #fff;
    border: none;
    bottom: 10px;
    cursor: pointer;
    position: fixed;
    left: 10px;
    outline: none;
    width: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    z-index: 1000;
}
