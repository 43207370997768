.p-component-overlay.p-dialog-visible {
    z-index: 1010 !important;
}

.table-heading {
    display: flex;
    flex-flow: row nowrap;
}

.offer-item {
    margin-bottom: 40px;
}

.offer-item__image {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    height: 60px;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
    background: var(--primary-color) !important;
    border: var(--primary-color) !important;
}

.datatable-responsive {
    position: relative;
}

.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

/* .add-offer-button {
    bottom: 0;
    height: 60px !important;
    position: absolute !important;
    right: 0px;
} */

.checkbox-fieldset .p-fieldset-content {
    display: flex;
    flex-flow: row wrap;
}

.checkbox-fieldset .p-fieldset-content .p-field-checkbox {
    flex: 1 1 50%;
}

.checkbox-fieldset .form__error-message {
    flex: 1 0 100%;
    margin-bottom: 10px;
}

@media screen and (max-width: 640px) {
    .table-heading {
        display: flex;
        flex-flow: column wrap;
    }

    .table-heading > span:last-of-type::before {
        content: none;
        margin-left: 0;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr {
        border-bottom: 1px solid #e9ecef;
        display: flex;
        flex-flow: row wrap;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        border: none;
        flex: 0 1 50%;
        padding: 0.5rem 1rem;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:first-of-type {
        flex: 1 1 100%;
        font-weight: bold;
        order: 0;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:nth-of-type(2) {
        display: none;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:nth-of-type(3) {
        order: 1;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:nth-of-type(4) {
        order: 2;
        text-align: right;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:nth-of-type(5) {
        flex: 1 1 100%;
        order: 5;
        text-align: right;
    }

    /* .add-offer-button {
        bottom: 0;
        height: 40px;
        position: relative;
        right: 0;
    } */

    .checkbox-fieldset .p-fieldset-content .p-field-checkbox {
        flex: 1 1 100%;
    }
}

.menu {
    align-items: flex-end;
    display: flex;
}

.menu .conditional-switch__container {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
}

.menu .conditional-switch {
    margin-left: 0px !important;
}

.menu .conditional-wrapped-content {
    flex: 1 1 auto;
}

.menu .conditional-switch__container {
    align-items: center;
    flex-flow: row;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
