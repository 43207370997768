.legal {
    line-height: 1.4em;
    margin-bottom: 100px;
}

.legal__ol--nested,
.legal__ol--nested ol {
    counter-reset: item;
}

.legal__ol--nested li {
    display: block;
    margin: 1em 0;
}

.legal__ol--nested > li {
    display: block;
    content: counters(item, ".") " ";
}

.legal__ol--nested > li::before {
    content: counters(item, ".") " ";
    font-weight: bold;
}

.legal__ol--nested li::before {
    content: counters(item, ".") " ";
    counter-increment: item;
}

@media screen and (max-width: 640px) {
    .legal__ol--nested,
    .legal__ol--nested ol {
        padding-left: 0;
    }
}
