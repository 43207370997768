@media screen and (max-width: 640px) {
    .admin__store-list-item {
        align-items: flex-start;
        flex-flow: column;
    }

    .admin__store-list-item + .admin__store-list-item {
        margin: 32px 0;
    }

    .admin__store-list-item a + a {
        margin-top: 16px;
    }
}
