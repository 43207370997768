.onboarding-steps {
    list-style: none;
    counter-reset: onboarding-steps;
    margin: 40px 0;
    padding-left: 40px;
    position: relative;
}

.onboarding-steps li {
    counter-increment: onboarding-steps;
    line-height: 1.5em;
    margin: 0 0 2rem 0;
    position: relative;
}

.onboarding-steps .inactive {
    color: gray;
}

.onboarding-steps li::before {
    align-items: center;
    background: var(--primary-color);
    border-radius: 50%;
    color: #fff;
    content: counter(onboarding-steps);
    display: inline-flex;
    height: 32px;
    justify-content: center;
    left: -42px;
    margin-right: 10px;
    position: absolute;
    top: -5px;
    width: 32px;
}

.button-disabled:hover {
    border: 1px solid #607d8b !important;
    color: #607d8b !important;
}

.p-progress-spinner-circle {
    stroke: var(--primary-color) !important;
}
