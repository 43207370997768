.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #00a19a !important;
}

.p-calendar {
    flex: 1;
}

.working-day {
    margin: 10px 0;
    padding: 10px 0;
}

.working-day + .working-day {
    border-top: 1px solid #ddd;
}

.working-day__label {
    font-weight: bold;
}

.conditional-switch__container {
    align-items: center;
    display: flex;
    flex-flow: row;
    margin: 5px 0;
    min-height: 36px;
}

.conditional-switch {
    margin-left: 0 !important;
    flex: 0 0 auto;
}

.conditional-wrapped-content {
    flex: 1 1 auto;
}

.working-day {
    display: flex;
    flex-flow: column;
}

.working-day__times .working-day__time-picker:nth-of-type(2) .working-day__time-picker-label {
    margin: 0 10px;
}

.working-day__time-picker-container {
    display: flex;
    flex-flow: row;
}

.working-day__time-picker {
    align-items: center;
    display: flex;
    flex: 1 1 50%;
    flex-flow: row;
}

.working-day__time-picker-label {
    margin-right: 10px;
}
