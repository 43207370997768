.sidebar__main-nav {
    border: none !important;
    width: 100% !important;
}

.p-sidebar-content {
    margin-top: 20px;
}

.p-sidebar-content .p-dropdown {
    width: 100%;
}

.p-sidebar.p-component.p-sidebar-active {
    z-index: 1002 !important;
}

.p-component-overlay.p-sidebar-mask {
    z-index: 1001 !important;
}

.menu-section__headline {
    background: transparent;
    text-transform: uppercase;
}

.menu-section__headline .p-menuitem-link:hover {
    background: transparent !important;
    cursor: initial;
}
